<template>
    <Layout class="blobs-bg">
        <template v-slot:top>
            <h2 class="w-10">Nieuws</h2>
        </template>
        <template v-slot:content>
            <template v-for="item in articles">
                <router-link :to="{ name: 'news_single', params: { id: item.id }}" class="flex flex-wrap mt-m rounded hover:bg-hover transition py-r" :key="item.id">
                    <div class="list__thumb pr-l w-12 s-up:w-2 flex-grow-0 flex-shrink-0">
                        <div class="ratio-5by4 rounded overflow-hidden bg-gray-200">
                            <img
                                v-if="item.intro_image"
                                v-lazy="cdnurl(item.intro_image)"
                                :data-srcset="cdnurl(item.intro_image) + '-100w 100w, ' + cdnurl(item.intro_image) + '-200w 200w, ' + cdnurl(item.intro_image) + '-440w 440w'"
                                :alt="item.name"
                                class="ratio-stretch object-cover"
                            />
                        </div>
                    </div>
                    <div class="w-12 s-up:w-10 leading-xs xs:mt-l">
                        <span class="size-7 color-tertiary">{{ timestamp_to_date(item.created.timestamp) }}</span>
                        <h2 class="size-4">{{ item.name }}</h2>
                        <div class="color-secondary" v-html="$options.filters.nl2br(item.intro_text_nl)" />
                        <div class="text-underline color-accent hover:color-primary mt-r">Lees meer &raquo;</div>
                    </div>
                </router-link>
            </template>
        </template>
        <template v-slot:sidebar>
            <Sidebar type="news" />
        </template>
    </Layout>
</template>

<script>
import kebabcase from 'lodash.kebabcase';
import Layout from '@/components/Layout-Sidebar.vue';
import Sidebar from '@/components/Sidebar.vue';

export default {
    name: 'News',
    components: { Sidebar, Layout },
    data() {
        return {
            articles: [],
        };
    },
    created() {
        this.getData();
    },
    methods: {
        kebabcase,

        timestamp_to_date(val) {
            if (typeof val === 'number') {
                const timestamp = val * 1000;
                const date = new Date(timestamp);
                return new Intl.DateTimeFormat('nl-NL', { day: '2-digit', month: '2-digit' }).format(date);
            }
            return val;
        },

        getData() {
            this.$store.dispatch('get', 'items/news').then(this.processData);
        },

        processData(r) {
            this.articles = r;
        },

        setMeta() {
            this.$store.commit('setMeta', {
                data: {
                    title: 'Nieuws',
                    titleDefault: `${process.env.VUE_APP_NAME}`,
                },
            });
        },
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },
    },
    watch: {
        data: {
            handler: 'setMeta',
            immediate: true,
        },
    },
};
</script>

<style>

</style>
